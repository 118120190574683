/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
// import { graphql, Link, useStaticQuery } from 'gatsby'
import { useScroll } from '../../../common/hooks'
import { SEO } from '@renderbus/common/components'
import { useClickOutside } from '@renderbus/common/hooks'
// import { RC2024Banner } from '../molecules/banner'
import { useMedia } from 'use-media'
import {
  PageContainer,
  IndexSection,
  RCHeader,
  HeaderItem,
  SignButton,
  SignUpButton,
  BackToTop,
  QrContainer,
  NavigateMenuIcon,
  NavigateMenu,
  NavigateMenuItem,
  RCOrganization,
  RCStyle,
  ImgWrapper,
  Slash,
  RCWordsSection,
  RCDateSection,
  RCGroupSection,
  RCAwardSection,
  AwardItem,
  RCJudgesSection,
  RCContentSection,
  RCDesignSection,
  RCAboutSection,
  AwardType,
  ProfileContainer,
  PageFooter,
  AwardContentMobile,
  RCTitle,
  PreviousContain,
  VideoBanner,
  AwardTotal,
  MainJudges,
  MainJudgeItem,
  ResultsAnnouncedWrap,
  RankAnnouncedWrap,
  RankWorkInfoWrap,
  RankWorkInfoHeader,
  RankWorkButton,
  RankVideo,
  RankContent,
  RankInfoWrap,
  RankInfoSidebarItem,
  RankInfoSidebar,
  RankInfoSidebarItemName,
  AwardsWrap,
  AwardsSidebar,
  AwardsScrollBar,
  AwardsSidebarItemsWrap,
  AwardsSidebarItem,
  AwardsSidebarItemName,
  AwardsInfoWrap,
  AwardsInfoHeader,
  AwardsInfoHeaderItem,
  AwardsScrollBarItem,
  RankInfoLeftWrap,
  RankInfoDesc,
  HistoryMatch,
  HistoryMatchDropDown,
  HistoryMatchItem,
} from './rc2024.atom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import PauseImg from '../images/render-contest-2023/play@2x.png'
import PoliceIcon from '../../../common/components/footer/images/police.png'

import QrCode from '../images/render-contest/qr_code.png'
import SloganIcon from '../images/render-contest/slogan.png'
import SloganMobileIcon from '../images/render-contest/slogan_mobile.png'
import PageBg from '../images/render-contest/page_bg.png'
import PageMobileBg from '../images/render-contest/page_mobile_bg.png'
import Title01 from '../images/render-contest/title_01.png'
import Title02 from '../images/render-contest/title_02.png'
import Title03 from '../images/render-contest/title_03.png'
import Title04 from '../images/render-contest/title_04.png'
import Title05 from '../images/render-contest/title_05.png'
import Title06 from '../images/render-contest/title_06.png'
import Title07 from '../images/render-contest/title_07.png'
import RenderContestTime from '../images/render-contest/render_time.png'
import RenderContestTimeMobile from '../images/render-contest/render_time_mobile.png'
import Group from '../images/render-contest/group.png'
import Curve from '../images/render-contest/curve.png'
import GroupsMobile from '../images/render-contest/groups_mobile.png'
import Award01 from '../images/render-contest/award_01.png'
import Award02 from '../images/render-contest/award_02.png'
import Award03 from '../images/render-contest/award_03.png'
import Award04 from '../images/render-contest/award_04.png'
import Style3D01 from '../images/render-contest/style3d_01.png'
import Style3D02 from '../images/render-contest/style3d_02.png'
import Style3D03 from '../images/render-contest/style3d_03.png'
import Style3D04 from '../images/render-contest/style3d_04.png'
import FaceGood01 from '../images/render-contest/facegood_01.png'
import FaceGood02 from '../images/render-contest/facegood_02.png'
import Zeno01 from '../images/render-contest/zeno_01.png'
import Wanghua01 from '../images/render-contest/wanghua_01.png'
import More01 from '../images/render-contest/more_01.png'
import More02 from '../images/render-contest/more_02.png'
import More03 from '../images/render-contest/more_03.png'
import More04 from '../images/render-contest/more_04.png'
// import JudgesAndong from '../images/render-contest/judges_andong.png'
import JudgesBian from '../images/render-contest/judges_bian.png'
import JudgesDeng from '../images/render-contest/judges_deng.png'
import JudgesDong from '../images/render-contest/judges_dong.png'
import JudgesDuan from '../images/render-contest/judges_duan.png'
import JudgesGao from '../images/render-contest/judges_gao.png'
import JudgesLu from '../images/render-contest/judges_lu.png'
import JudgesLuo from '../images/render-contest/judges_luo.png'
// import JudgesMu from '../images/render-contest/judges_mu.png'
import JudgesPeng from '../images/render-contest/judges_peng.png'
import JudgesRofix from '../images/render-contest/judges_rofix.png'
// import JudgesSheng from '../images/render-contest/judges_sheng.png'
import JudgesSun from '../images/render-contest/judges_sun.png'
import JudgesShi from '../images/render-contest/judges_shi.png'
import JudgesWei from '../images/render-contest/judges_wei.png'
import JudgesYang from '../images/render-contest/judges_yang.png'
import JudgesYu from '../images/render-contest/judges_yu.png'
import JudgesYuRuying from '../images/render-contest/judges_yuruying.png'
import JudgesZhou from '../images/render-contest/judges_zhou.png'
import JudgesZhoulijun from '../images/render-contest/judges_zhoulijun.png'
// import JudgesZou from '../images/render-contest/judges_zou.png'
import JudgesLajiao from '../images/render-contest/judges_lajiao.png'
// import Judges01Andong from '../images/render-contest/judges_01_andong.png'
import Judges02Bian from '../images/render-contest/judges_02_bian.png'
import Judges03Deng from '../images/render-contest/judges_03_deng.png'
import Judges04Dong from '../images/render-contest/judges_04_dong.png'
import Judges05Duan from '../images/render-contest/judges_05_duan.png'
import Judges06Gao from '../images/render-contest/judges_06_gao.png'
import Judges07Lu from '../images/render-contest/judges_07_lu.png'
import Judges08Luo from '../images/render-contest/judges_08_luo.png'
// import Judges09Mu from '../images/render-contest/judges_09_mu.png'
import Judges10Peng from '../images/render-contest/judges_10_peng.png'
import Judges11Rofix from '../images/render-contest/judges_11_rofix.png'
// import Judges12Sheng from '../images/render-contest/judges_12_sheng.png'
import Judges13Sun from '../images/render-contest/judges_13_sun.png'
import Judges14Shi from '../images/render-contest/judges_14_shi.png'
import Judges15Wei from '../images/render-contest/judges_15_wei.png'
import Judges16Yang from '../images/render-contest/judges_16_yang.png'
import Judges17Yu from '../images/render-contest/judges_17_yu.png'
import Judges18Yu from '../images/render-contest/judges_18_yu.png'
import Judges19Zhou from '../images/render-contest/judges_19_zhou.png'
import Judges20Zhou from '../images/render-contest/judges_20_zhou.png'
// import Judges21Zou from '../images/render-contest/judges_21_zou.png'
import Judges22Lajiao from '../images/render-contest/judges_22_lajiao.png'
// import JudgesHover01 from '../images/render-contest/judges_hover_01.png'
import JudgesHover02 from '../images/render-contest/judges_hover_02.png'
import JudgesHover03 from '../images/render-contest/judges_hover_03.png'
import JudgesHover04 from '../images/render-contest/judges_hover_04.png'
import JudgesHover05 from '../images/render-contest/judges_hover_05.png'
import JudgesHover06 from '../images/render-contest/judges_hover_06.png'
import JudgesHover07 from '../images/render-contest/judges_hover_07.png'
import JudgesHover08 from '../images/render-contest/judges_hover_08.png'
// import JudgesHover09 from '../images/render-contest/judges_hover_09.png'
import JudgesHover10 from '../images/render-contest/judges_hover_10.png'
import JudgesHover11 from '../images/render-contest/judges_hover_11.png'
// import JudgesHover12 from '../images/render-contest/judges_hover_12.png'
import JudgesHover13 from '../images/render-contest/judges_hover_13.png'
import JudgesHover14 from '../images/render-contest/judges_hover_14.png'
import JudgesHover15 from '../images/render-contest/judges_hover_15.png'
import JudgesHover16 from '../images/render-contest/judges_hover_16.png'
import JudgesHover17 from '../images/render-contest/judges_hover_17.png'
import JudgesHover18 from '../images/render-contest/judges_hover_18.png'
import JudgesHover19 from '../images/render-contest/judges_hover_19.png'
import JudgesHover20 from '../images/render-contest/judges_hover_20.png'
// import JudgesHover21 from '../images/render-contest/judges_hover_21.png'
import JudgesHover22 from '../images/render-contest/judges_hover_22.png'
import CriteriaSlice from '../images/render-contest/criteria_slice.png'
import LogoRenderbus from '../images/render-contest/logo_renderbus.png'
import LogoQingjiao from '../images/render-contest/logo_qingjiao.png'
import LogoStyle3d from '../images/render-contest/logo_style3d.png'
import LogoFacegood from '../images/render-contest/logo_facegood.png'
import LogoZesen from '../images/render-contest/logo_zesen.png'
import LogoPbrmax from '../images/render-contest/logo_pbrmax.png'
import LogoRenderbusMobile from '../images/render-contest/logo_renderbus_mobile.png'
import LogoQingjiaoMobile from '../images/render-contest/logo_qingjiao_mobile.png'
import LogoStyle3dMobile from '../images/render-contest/logo_style3d_mobile.png'
import LogoFacegoodMobile from '../images/render-contest/logo_facegood_mobile.png'
import LogoZesenMobile from '../images/render-contest/logo_zesen_mobile.png'
import LogoPbrmaxMobile from '../images/render-contest/logo_pbrmax_mobile.png'
import LogoZhenxiang from '../images/render-contest/logo_zhenxiang.png'
import LogoHuiWang from '../images/render-contest/logo_huiwang.png'
import Logo3Dcat from '../images/render-contest/logo_3dcat.png'
import LogoRayvision from '../images/render-contest/logo_rayvision.png'
import LogoLianxiang from '../images/render-contest/logo_lianxiang.png'
import LogoRenderbusPartner from '../images/render-contest/logo_renderbus_partner.png'
import LogoQingjiaoPartner from '../images/render-contest/logo_qingjiap_partner.png'
import LogoStyle3dPartner from '../images/render-contest/logo_style3d_partner.png'
import LogoFacegoodPartner from '../images/render-contest/logo_facegood_partner.png'
import LogoZesenPartner from '../images/render-contest/logo_zesen_partner.png'
import LogoPbrmaxPartner from '../images/render-contest/logo_pbrmax_partner.png'
import LogoLuokeke from '../images/render-contest/logo_luokeke.png'
import LogoD5 from '../images/render-contest/logo_d5.png'
import ThanksLiangxiang from '../images/render-contest/thanks_liangxiang.png'
import IconWord from '../images/render-contest/icon_word.png'
import QrcodeTalk from '../images/render-contest/qrcode.png'
import IconLeft from '../images/render-contest/icon_left.png'
import IconRight from '../images/render-contest/icon_right.png'
// import Planet from '../images/render-contest/planet.png'
import PullIcon from '../images/render-contest/icon_pull.png'
import UpIcon from '../images/render-contest/icon_up.png'
import DownloadImg from '../images/render-contest/download@2x.png'
import BannerBg from '../images/render-contest/banner_bg.png'
import BannerBgMb from '../images/render-contest/banner_bg_mb.png'
import MainJudge01 from '../images/render-contest/main_judge_01.png'
import MainJudge01Hover from '../images/render-contest/main_judge_01_hover.png'
import MainJudge02 from '../images/render-contest/main_judge_02.png'
import MainJudge03 from '../images/render-contest/main_judge_03.png'
import MainJudge04 from '../images/render-contest/main_judge_04.png'
import IconMore from '../images/render-contest/icon_more.png'
import ChampionWheat from '../images/render-contest/champion-wheat.png'
import RunnerUpWheat from '../images/render-contest/runner-up-wheat.png'
import ThirdWheat from '../images/render-contest/third-wheat.png'
import CommonWheat from '../images/render-contest/common-wheat.png'
import AwardsBg from '../images/render-contest/awards-bg.png'

const headerItemList = [
  {
    label: '首页',
    link: '#rc-top',
  },
  {
    label: '赛事详情',
    link: '#rc-time',
  },
  {
    label: '赛事资料',
    link: '#rc-content',
  },
  {
    label: '常见问题',
    link: '#rc-design',
  },
  {
    label: '往届回顾',
  },
]
const mobileMenuItemList = [
  {
    label: '赛果公布',
    link: '#rc-announced',
  },
  {
    label: '大赛引言',
    link: '#rc-words',
  },
  {
    label: '大赛时间',
    link: '#rc-time',
  },
  {
    label: '面向群体',
    link: '#rc-group',
  },
  {
    label: '大赛奖品',
    link: '#rc-award',
  },
  {
    label: '评委阵容',
    link: '#rc-judges',
  },
  {
    label: '大赛内容',
    link: '#rc-content',
  },
  {
    label: '设计说明',
    link: '#rc-design',
  },
  {
    label: '关于大赛',
    link: '#rc-about',
  },
]
const words = [
  ['灵感汇聚，云端接力', '三维造物，渲染赋形', '艺术共生，创意无界'],
  ['创作各显神通，风格百花齐放', '七秒方寸之间，次元瞬息万变'],
  ['瑞云官方流量扶持，大V媒体精准曝光', '高视野的行业交流，更多的业界合作机会', '只等你的参与！'],
]
const awardGroupMap2 = [
  {
    name: 'first',
    title: '冠军',
    num: '1人',
    total: '14W+',
    amount: '￥50,000现金',
    other: [
      {
        amount: '35,000元',
        content: [
          {
            tip: `联想拯救者刃9000K 专业台式电脑+Y9000P 专业电竞本+T7电动升降桌+Y700 高性能平板+Y27系列显示器+C7电竞椅+K7机械键盘+M7无线游戏鼠标+Z4散热支架`,
          },
        ],
      },
      {
        amount: '29,000元',
        content: [{ award: `ROKOKO一体式动作捕捉系统` }],
      },
      {
        amount: '10,000元',
        content: [{ award: `臻像科技40cm*60cm裸眼3D光场全息画`, tip: '（画面内容为创作者定制）' }],
      },
      {
        amount: '8,999元',
        content: [{ award: `绘王Kamvas Pro 27数位屏` }],
      },
      {
        amount: '3,000元',
        content: [{ award: `Renderbus瑞云渲染无门槛渲染券3000元`, tip: '（6个月有效期）' }],
      },
      {
        amount: '2,000元',
        content: [{ award: '青椒云桌面2000元代金券', tip: '（6个月有效期）' }],
      },
      {
        amount: '1,999元',
        content: [{ award: 'Style3D Simulator 个人版', tip: '（1年使用权）' }],
      },
      {
        amount: '1,888元',
        content: [
          { award: 'Style3D Atelier 个人版', tip: '（1年使用权）' },
          { award: 'Style3D  4k+服装款式模型库', tip: '（1年使用权）' },
        ],
      },
      { amount: '2,680元', content: [{ award: 'PBRMAX大师版', tip: '（1年使用权）' }] },
      { amount: '2,000元', content: [{ award: 'PBRMAX下载次数加油包', tip: '（1年使用权）' }] },
      {
        amount: '1,920元',
        content: [{ award: 'D5渲染器专业版', tip: '（1年使用权）' }],
      },
    ],
    titleColor: 'linear-gradient(180deg, #FFD69B 49%, #B56D01 100%)',
    otherAmountColor: '#FFD69B',
    lineColor:
      'linear-gradient(90deg, rgba(216, 216, 216, 0), rgba(213, 210, 167, 1), rgba(216, 216, 216, 0)) 1 1',
    bgImg: Award01,
  },
  {
    name: 'second',
    title: '亚军',
    num: '3人',
    total: '6W+',
    amount: '￥30,000现金',
    other: [
      {
        amount: '11,878元',
        content: [
          { tip: '联想拯救者刃7000K 专业台式电脑+Y27系列显示器+M7无线游戏鼠标+K7机械键盘' },
        ],
      },
      {
        amount: '10,000元',
        content: [{ award: '臻像科技40cm*60cm裸眼3D光场全息画', tip: '（画面内容为创作者定制）' }],
      },
      {
        amount: '5,799元',
        content: [{ award: '绘王Kamvas Pro 16(4K)数位屏' }],
      },
      {
        amount: '2,000元',
        content: [{ award: 'Renderbus瑞云渲染无门槛渲染券2000元', tip: '（6个月有效期）' }],
      },
      {
        amount: '1,000元',
        content: [{ award: '青椒云桌面1000元代金券', tip: '（6个月有效期）' }],
      },
      {
        amount: '1,999元',
        content: [{ award: 'Style3D Simulator 个人版', tip: '（1年使用权）' }],
      },
      {
        amount: '1,888元',
        content: [
          { award: 'Style3D Atelier 个人版', tip: '（1年使用权）' },
          { award: 'Style3D  4k+服装款式模型库', tip: '（1年使用权）' },
        ],
      },
      { amount: '2,680元', content: [{ award: 'PBRMAX大师版', tip: '（1年使用权）' }] },
      { amount: '2,000元', content: [{ award: 'PBRMAX下载次数加油包', tip: '（1年使用权）' }] },
      {
        amount: '1,200元',
        content: [{ award: 'D5渲染器专业版', tip: '（6个月使用权）' }],
      },
    ],
    titleColor: 'linear-gradient(180deg, #C9E0F0 43%, #576783 100%)',
    otherAmountColor: '#E2F2FF',
    lineColor:
      'linear-gradient(90deg, rgba(216, 216, 216, 0), rgba(200, 224, 239, 1), rgba(216, 216, 216, 0)) 1 1',
    bgImg: Award02,
  },
  {
    name: 'third',
    title: '季军',
    num: '6人',
    total: '2W+',
    amount: '￥10,000现金',
    other: [
      {
        amount: '8,000元',
        content: [{ award: '联想拯救者Y7000P 高能电竞本' }],
      },
      {
        amount: '1,038元',
        content: [{ award: '绘王KD200数位板手绘板' }],
      },
      {
        amount: '1,500元',
        content: [{ award: 'Renderbus瑞云渲染无门槛渲染券1500元', tip: '（6个月有效期）' }],
      },
      { amount: '500元', content: [{ award: '青椒云桌面500元代金券', tip: '（6个月有效期）' }] },
      {
        amount: '1,999元',
        content: [{ award: 'Style3D Simulator 个人版', tip: '（1年使用权）' }],
      },
      {
        amount: '1,888元',
        content: [
          { award: 'Style3D Atelier 个人版', tip: '（1年使用权）' },
          { award: 'Style3D  4k+服装款式模型库', tip: '（1年使用权）' },
        ],
      },
      { amount: '2,680元', content: [{ award: 'PBRMAX大师版', tip: '（1年使用权）' }] },
      { amount: '2,000元', content: [{ award: 'PBRMAX下载次数加油包', tip: '（1年使用权）' }] },
      {
        amount: '600元',
        content: [{ award: 'D5渲染器专业版', tip: '（3个月使用权）' }],
      },
    ],
    titleColor: 'linear-gradient(180deg, #EBC4AB 48%, #AD694E 99%)',
    otherAmountColor: '#EBC4AB',
    lineColor:
      'linear-gradient(90deg, rgba(216, 216, 216, 0), rgba(214, 164, 139, 1), rgba(216, 216, 216, 0)) 1 1',
    bgImg: Award03,
  },
  {
    name: 'fourth',
    title: '优秀奖',
    num: '20人',
    total: '1W+',
    amount: '￥8,000现金',
    other: [
      {
        amount: '1,000元',
        content: [{ award: 'Renderbus瑞云渲染无门槛渲染券1000元', tip: '（6个月有效期）' }],
      },
      { amount: '200元', content: [{ award: '青椒云桌面200元代金券', tip: '（6个月有效期）' }] },
      {
        amount: '1,999元',
        content: [{ award: 'Style3D Simulator 个人版', tip: '（1年使用权）' }],
      },
      {
        amount: '1,888元',
        content: [
          { award: 'Style3D Atelier 个人版', tip: '（1年使用权）' },
          { award: 'Style3D  4k+服装款式模型库', tip: '（1年使用权）' },
        ],
      },
      {
        amount: '200元',
        content: [{ award: 'D5渲染器专业版', tip: '（1个月使用权）' }],
      },
    ],
    titleColor: 'linear-gradient(180deg, #E8F0FB 43%, #3D84E5 96%);',
    otherAmountColor: '#E5EEFB',
    lineColor:
      'linear-gradient(90deg, rgba(216, 216, 216, 0), rgba(163, 196, 242, 1), rgba(216, 216, 216, 0)) 1 1',
    bgImg: Award04,
  },
  {
    name: 'fifth',
    title: '最佳人气奖',
    num: '8人',
    total: '1W+',
    amount: '￥5,000现金',
    other: [
      {
        amount: '1,000元',
        content: [{ award: 'Renderbus瑞云渲染无门槛渲染券1000元', tip: '（6个月有效期）' }],
      },
      { amount: '500元', content: [{ award: '青椒云桌面500元代金券', tip: '（6个月有效期）' }] },
      {
        amount: '10,000元',
        content: [{ award: '臻像科技40cm*60cm裸眼3D光场全息画', tip: '（画面内容为创作者定制）' }],
      },
      {
        amount: '200元',
        content: [{ award: 'D5渲染器专业版', tip: '（1个月使用权）' }],
      },
      { content: [{ award: '*投稿作品需为入围作品可参与本奖项评比' }] },
    ],
    titleColor: 'linear-gradient(180deg, #E8F0FB 43%, #3D84E5 96%);',
    otherAmountColor: '#E5EEFB',
    lineColor:
      'linear-gradient(90deg, rgba(216, 216, 216, 0), rgba(163, 196, 242, 1), rgba(216, 216, 216, 0)) 1 1',
    bgImg: Award04,
  },
]
const specialAwardMap = new Map([
  [
    'Style3D特别奖4人',
    {
      class: 'style3d',
      awardsImgList: [Style3D01, Style3D02, Style3D03, Style3D04],
      awardContent: [
        {
          title: 'Style3D特别奖（4人）',
          titleContent: '投稿作品需使用Style3D Atelier或Style3D Simulator',
          totalAmount: '总奖金20,000元（单项5,000元）',
          content: [
            {
              amount: '· 价值1,999元',
              text: 'Style3D Simulator个人版一年使用权',
            },
            {
              amount: '· 价值1,888元',
              text: 'Style3D Atelier个人版一年使用权',
            },
            {
              text: '· Style3D 4k+服装款式模型库 一年使用',
            },
            {
              text: '· 特别优秀作品可获GDC展',
            },
          ],
        },
      ],
    },
  ],
  [
    'FACEGOOD特别奖2人',
    {
      class: 'facegood',
      awardsImgList: [FaceGood01, FaceGood02],
      awardContent: [
        {
          title: 'FACEGOOD特别表情动画⼤师奖（1人）：',
          titleContent:
            '参赛选⼿⾄少使⽤FACEGOOD任意⼀款产品类型或技术来完成参赛，在⼈脸表情跟踪、解算和渲染⽅⾯有卓越的表情动画表现，呈现出⾼保真、⽣动逼真的表情动画效果',
          totalAmount: '奖品总价136,999元',
          content: [
            {
              amount: '· 奖金5,000元',
            },
            {
              amount: '· 价值11,999元',
              text: '1台D4双⽬⽴体⾯部表情捕捉头盔',
            },
            {
              amount: '· 价值120,000元',
              text: 'Avatary企业版账⼾1年权益',
            },
          ],
        },
        {
          title: '⾯部表情动画创意奖（1人）：',
          titleContent:
            '⽆FACEGOOD产品使⽤权益参赛作品，需含模型或⼈物的⾯部动画，且在⾯部表情动画⽅⾯表现出卓越技巧和创意⼒，考虑模型复杂性、表情动画细节性逼真性',
          totalAmount: '奖品总价61,999元',
          content: [
            {
              amount: '· 价值1,999元',
              text: '1台D2单⽬⾯部表情捕捉头盔',
            },
            {
              amount: '· 价值60,000元',
              text: ' Avatary企业版账⼾半年权益',
            },
          ],
        },
      ],
    },
  ],
  [
    'ZENO特别奖1人',
    {
      class: 'zeno',
      awardsImgList: [Zeno01],
      awardContent: [
        {
          title: 'ZENO特别奖（1人）',
          titleContent: '投稿作品需采⽤ZENO进⾏某⼀环节或功能完成作品',
          totalAmount: '奖品总价25,000',
          content: [
            {
              amount: '· 奖⾦ 5000元/iPhone 15(128G)',
            },
            {
              amount: '· 价值19,999元',
              text:
                'ZENO肌肉仿真/布料解算/流体解算 商用子图永久授权（仅限于赛事教程版本）+其他ZENO商用工具 （仅限于赛事教程版本）',
            },
          ],
        },
      ],
    },
  ],
  [
    'PBRMAX特别奖1人',
    {
      class: 'wanghua',
      awardsImgList: [Wanghua01],
      awardContent: [
        {
          title: 'PBRMAX特别奖（1人）',
          titleContent: '投稿作品需使用PBRMAX数字资产',
          totalAmount: '奖品总价5000+',
          content: [
            {
              amount: '· 价值2680元',
              text: 'PBRMAX大师版1年',
            },
            {
              amount: '· 价值2000元',
              text: 'PBRMAX下载次数加油包',
            },
            {
              text: '· 特邀产品体验官、KOC塑造、流媒体平台投流扶持',
            },
            {
              text: '· EcoPlants周边大礼',
            },
          ],
        },
      ],
    },
  ],
  [
    '更多荣誉',
    {
      class: 'more',
      awardsImgList: [More01, More02, More03, More04],
      awardContent: [],
    },
  ],
])
const mainJudgesList1 = [
  {
    name: '边青山',
    img: JudgesBian,
    imgHover: Judges02Bian,
    infoAvatar: JudgesHover02,
    intro: ['鲁迅美术学院传媒动画学院游戏设计工作室教师'],
    position: ['鲁迅美术学院传媒动画学院', '游戏设计工作室教师'],
    positionMb: ['鲁迅美术学院', '传媒动画学院', '游戏设计工作室教师'],
  },
  {
    name: '邓强',
    img: JudgesDeng,
    imgHover: Judges03Deng,
    infoAvatar: JudgesHover03,
    intro: [
      '西安美术学院影视动画系主任、三维动画工作室负责人、教授，硕士研究生导师，设计学博士。Autodesk M&E 三维动画教育专家(China)、Autodesk 3dsmax国际讲师、中国美术家协会会员、动画导演。研究方向：三维动画。',
      '主要作品有《案板江湖》、《speedfire》、《城》、《十八重天》等。',
    ],
    position: ['西安美术学院影视动画系', '主任'],
    positionMb: ['西安美术学院影视动', '画系主任'],
  },
  {
    name: '董艳',
    img: JudgesDong,
    imgHover: Judges04Dong,
    infoAvatar: JudgesHover04,
    intro: [
      'CG模型网CEO、中央美术学院国际学院数字艺术研究中心副主任、中国电影美术学会CG艺术专委会副秘书长、湖南省动漫游戏协会CG数字艺术专委会主任',
    ],
    position: ['CG模型网CEO'],
    positionMb: ['CG模型网CEO'],
  },
  {
    name: '段雯锴',
    img: JudgesDuan,
    imgHover: Judges05Duan,
    infoAvatar: JudgesHover05,
    intro: [
      '中国传媒大学动画与数字艺术学院教师、动画导演、动画创作人。段雯锴于2012年创作动画短片《抢狮头》，该短片一举斩获包括中国动画美猴奖、东京国际动画节评委会特别奖等20余项殊荣。作品《沉默之蓝：序》已在全世界超过40个动画电影节中获奖入围，包括具有奥斯卡和英国学院奖认证资质的洛杉矶国际短片电影节、巴西AnimaMundi国际动画节、罗马尼亚Animest国际动画节等。',
    ],
    position: ['中国传媒大学', '动画与数字艺术学院教师'],
    positionMb: ['中国传媒大学动画', '与数字艺术学院教师'],
  },
  {
    name: '高汉威',
    img: JudgesGao,
    imgHover: Judges06Gao,
    infoAvatar: JudgesHover06,
    intro: [
      '韩国国立首尔大学设计学博士，韩国设计协会会员，广州美术学院视觉艺术设计学院专任教师、多年从事数字美术、多媒体及数字娱乐方面的教学研究工作。',
    ],
    position: ['韩国国立首尔大学', '设计学博士'],
    positionMb: ['韩国国立首尔大学设', '计学博士'],
  },
  {
    name: '卢东彪',
    img: JudgesLu,
    imgHover: Judges07Lu,
    infoAvatar: JudgesHover07,
    intro: [
      '腾讯游戏美术专家、腾讯游戏 天美工作室群 Z1 Studio元宇宙项目组 美术负责人、中央美术学院特聘教、四川美术学院特邀专家、清华大学研究生院校外导师、中国电影美术学会CG专委会副主任、鲁迅美术学院传媒动画学院特邀硕士生导师、中央美术学院电影VEX工作室特邀教授',
    ],
    position: ['腾讯游戏美术专家', '中央美术学院特聘教授'],
    positionMb: ['腾讯游戏美术专家', '中央美术学院特聘', '教授'],
  },
  {
    name: '骆駪駪',
    img: JudgesLuo,
    imgHover: Judges08Luo,
    infoAvatar: JudgesHover08,
    intro: [
      '北京电影学院影视技术系教师，中国电影电视技术学会先进影像专业委员会秘书长，光大集团青旅集团文旅智库专家顾问，国际沉浸娱乐协会（IMERSA）会员。研究和设计集中在沉浸式数字影像在文化遗产中的应用，涉及球幕技术、虚拟现实技术、MR、XR、人机交互等领域。',
    ],
    position: ['北京电影学院', '影视技术系教师'],
    positionMb: ['北京电影学院', '影视技术系教师'],
  },
  {
    name: '彭程',
    img: JudgesPeng,
    imgHover: Judges10Peng,
    infoAvatar: JudgesHover10,
    intro: ['PBRMAX创始人兼CEO、中国科技艺术新生代领军人物'],
    position: ['PBRMAX创始人兼CEO', '中国科技艺术新生代领军人'],
    positionMb: ['PBRMAX', '创始人兼CEO'],
  },
  {
    name: 'Rofix',
    img: JudgesRofix,
    imgHover: Judges11Rofix,
    infoAvatar: JudgesHover11,
    intro: [
      'CG动画导演，前腾讯主美，毕业于UCLA（加州大学洛杉矶分校）和RISD（罗德岛设计学院）。擅长VR动画（Quill），创作了首部完全在VR里制作的短片动画，曾受邀在Facebook讲座以及登上3D Artist杂志专栏',
    ],
    position: ['CG动画导演', '前腾讯主美'],
    positionMb: ['CG动画导演', '前腾讯主美'],
  },
  {
    name: '孙略',
    img: JudgesSun,
    imgHover: Judges13Sun,
    infoAvatar: JudgesHover13,
    intro: [
      '北京电影学院教授，影视技术副主任，硕士研究生导师。长期从事数字影像领域的教学、研究与创作工作。代表性作品：《雪花工场》、《奇石工场》、《停顿的虚像》、《静止的电影》、《扭曲的时间》等',
    ],
    position: ['北京电影学院教授', '影视技术副主任'],
    positionMb: ['北京电影学院教授', '影视技术副主任'],
  },
  {
    name: '史叶',
    img: JudgesShi,
    imgHover: Judges14Shi,
    infoAvatar: JudgesHover14,
    intro: ['虚幻引擎授权导师 Unreal Engine Authorized Instructor'],
    position: ['虚幻引擎授权导师'],
    positionMb: ['虚幻引擎授权导师'],
  },
  {
    name: '魏春明',
    img: JudgesWei,
    imgHover: Judges15Wei,
    infoAvatar: JudgesHover15,
    intro: [
      '上海工艺美术学会数字艺术设计专业委员会副主任',
      '第五届、第八届全国高校数字艺术设计大赛（NCDA）评委、学术委员会委员',
    ],
    position: ['上海工艺美术学会数字艺术', '设计专业委员会副主任'],
    positionMb: ['上海工艺美术学会', '数字艺术设计专业', '委员会副主任'],
  },
  {
    name: '杨翰',
    img: JudgesYang,
    imgHover: Judges16Yang,
    infoAvatar: JudgesHover16,
    intro: [
      '虚幻引擎电影人，从电影特效行业转行到3A游戏，把电影叙事带入游戏制作里。油管up主，频道UnrealHan，发布虚幻引擎教程，电影制作心得和最新特效科技测评。曾入职过多家国外知名特效公司：METHODSTUDIOS，MPC，参与过多部好莱坞特效影片制作：《金刚狼3》（logan），《星际迷航：发现号》（Star trek：Discovery)，《海王》（Aquaman），《鲨滩》（The Shallows)',
    ],
    position: ['著名虚幻引擎电影人', '参与制作《金刚狼3》等'],
    positionMb: ['著名虚幻引擎电影人', '参与制作《金刚狼3》等'],
  },
  {
    name: '于朕',
    img: JudgesYu,
    imgHover: Judges17Yu,
    infoAvatar: JudgesHover17,
    intro: [
      '我国第一代科幻设计师，主持创建全球首所元宇宙艺术学院，现任中国美术学院创新设计学院副院长，AI中心常务副主任，省十四五数字艺术混合现实重点实验室主任，研究涵盖科艺融合交叉、数字艺术与混合现实、未来建筑及科幻设计实践。主持国家教育部人文社科项目《老年人工智能下肢支架设计研究》及教育部产学协同育人项目《混合现实与多维感知教学内容与教学体系改革》等。荣获“庆祝新中国成立70周年” 华美奖及突出贡献个人奖，意大利THE PlAN大奖等。并入选【2016】中国文旅部双创产业重点人才库及中国美术学院领航人才计划，中国美术学院数字媒体艺术专业带头人。',
    ],
    position: ['中国美术学院创新设计学', '院副院长'],
    positionMb: ['中国美术学院', '创新设计学院副院长'],
  },
  {
    name: '余如莹',
    img: JudgesYuRuying,
    imgHover: Judges18Yu,
    infoAvatar: JudgesHover18,
    intro: [
      'Motion Designer\\Art Director',
      '从业10年，原工业设计专业，中国一线动态视觉设计师、美术指导，主要从事产品动画，概念影片以及其他三维视觉设计和导演 等工作，从制作到出品，得到了行业和品牌的认可。',
      '曾监制参与《时光代理人》OP、Bilibili寻光系列后期总监及部分镜头制作、以及vivo、oppo、小米等多家品牌手机外观概念宣传片',
    ],
    position: ['Motion Designer', 'Art Director'],
    positionMb: ['Motion Designer', 'Art Director'],
  },
  {
    name: '周立均',
    img: JudgesZhoulijun,
    imgHover: Judges19Zhou,
    infoAvatar: JudgesHover19,
    intro: [
      '广州美术学院副教授，硕士生导师，数字娱乐教研中心主任，广州亚运会吉祥物主创，研究方向为游戏、动画、数字孪生与数字文旅。',
      '现为中国图像图形学学会数码艺术专委会委员、广东省动漫艺术家协会理事、广东省文化学会青少年文艺专委会副主任、广东省游戏行业协会顾问、广东省计算机学会航空航天科技应用专委会智库专家、广东省综合评标评审专家、广州市非物质文化遗产保护工作专委会委员、广州市海珠区创意文化顾问',
    ],
    position: ['广州美术学院副教授', '硕士生导师'],
    positionMb: ['广州美术学院副教授', '硕士生导师'],
  },
  {
    name: '周罡',
    img: JudgesZhou,
    imgHover: Judges20Zhou,
    infoAvatar: JudgesHover20,
    intro: [
      '湖北美术学院影视动画学院教师',
      '个展:',
      '2011年 “寻找”个展 纽伦堡艺术学院展览厅 纽伦堡 德国',
      '2017年“CHINA CONNECTION I.” 斯图加特 德国',
      '2021年彼众空间 眼见为实 武汉',
    ],
    position: ['湖北美术学院影视动画学院', '院长助理'],
    positionMb: ['湖北美术学院影视动', '画学院 院长助理'],
  },
  {
    name: '只剩一瓶辣椒酱',
    img: JudgesLajiao,
    imgHover: Judges22Lajiao,
    infoAvatar: JudgesHover22,
    intro: [
      'BlenderCN成员，B站Blender专题内容创作者/开发者，Github开源小组AIGODLIKE发起者，AIGODLIKE社区站长',
    ],
    position: ['B站Blender专题内容', '创作者/开发者'],
    positionMb: ['B站Blender专题内容', '创作者/开发者'],
  },
]
const JudgesList = [
  {
    name: '安东尼·拉默里纳拉',
    englishName: 'Anthony LaMolinara',
    img: MainJudge01,
    imgHover: MainJudge01Hover,
    // imgHover: Judges01Andong,
    // infoAvatar: JudgesHover01,
    intro: ['第77届奥斯卡最佳特效导演奖得主 ，好莱坞电影大师'],
    introHover: [
      '美国导演、编剧、视觉特效、动画师、画家、雕塑家和音乐家，第77届奥斯卡最佳特效导演奖得主 ，好莱坞电影大师，奥斯卡最佳外语片、最佳动画片、最佳动画短片和最佳短片奖评委',
      '代表作有：《蜘蛛侠》、《蜘蛛侠 2》、《透明人》、《玩具总动员》、《玩具总动员 3》、《精灵鼠小弟》、《You Can, You Up》、《Triangle》、《最终幻想》、《刀锋战士》、《霸王龙》等，以及《阿凡达》的前期开发，Michael Jackson的多部音乐片作品等',
    ],
  },
  {
    name: '穆智飞',
    img: MainJudge02,
    // imgHover: Judges09Mu,
    // infoAvatar: JudgesHover09,
    intro: [
      '概念艺术指导、制作设计师、动画视效指导、中国电影艺术学会CG艺术委员会主任、中央美术学院电影视效⼯作室专业导师',
    ],
  },
  {
    name: '沈映',
    img: MainJudge03,
    // imgHover: Judges12Sheng,
    // infoAvatar: JudgesHover12,
    intro: ['欧特克传媒娱乐行业大中华区技术专家', '有着丰富的行业技术背景，涉足三维动画行业多年'],
  },
  {
    name: '邹琼',
    img: MainJudge04,
    // imgHover: Judges21Zou,
    // infoAvatar: JudgesHover21,
    intro: ['瑞云科技总经理', '业界领先的云渲染系统及高性能文件传输系统开创者'],
  },
]
const criteriaList = [
  {
    title: '技术应用',
    content: 'CG软件使用专业、熟练，模型、材质、渲染等制作质量优秀',
  },
  {
    title: '艺术美感',
    content:
      '画面表达流畅，视觉效果突出，整体设计具有美感和艺术价值，具有良好的传播观赏效果，有自己的风格亮点',
  },
  {
    title: '创意表现',
    content: '原创性和创意性：参赛作品是否具有独特的创意和新颖的表达方式，给人耳目一新的视觉感受',
  },
  {
    title: '契合主题',
    content:
      '对赛事主题概念想法的理解呈现，作品设计构思的故事性，剧情完整度，“大Y”元素的巧妙融合体现',
  },
]
const supportList = [
  {
    logo: LogoRenderbus,
    logoMobile: LogoRenderbusMobile,
    class: 'renderbus',
    text: '7X24h在线技术支持的一站式云渲染平台',
    learnBtn: '了解Renderbus',
    downloadBtn: '下载Renderbus',
    learnLink: 'https://www.renderbus.com/support-new/web-login/',
    downloadLink: 'https://www.renderbus.com/download.html',
    downloadRel: 'dofollow',
  },
  {
    logo: LogoQingjiao,
    logoMobile: LogoQingjiaoMobile,
    class: 'qingjiao',
    text: '设计师的高性能云端图形工作站',
    learnBtn: '了解青椒云',
    downloadBtn: '下载青椒云',
    learnLink: 'https://www.qingjiaocloud.com/',
    downloadLink: 'https://www.qingjiaocloud.com/download/',
    learnRel: 'dofollow',
    downloadRel: 'dofollow',
  },
  {
    logo: LogoStyle3d,
    logoMobile: LogoStyle3dMobile,
    class: 'style3d',
    text: '为CG行业而生，国产卓越的3D服装模拟工具',
    learnBtn: '下载Style3D Atelier',
    downloadBtn: '下载Style3D Simulator',
    learnLink: 'https://atelier.style3d.com/zh-CN',
    downloadLink: 'https://simulator.style3d.com/zh-CN',
  },
  {
    logo: LogoFacegood,
    logoMobile: LogoFacegoodMobile,
    class: 'facegood',
    text: 'Make A World of Avatars',
    learnBtn: '了解FACEGOOD',
    downloadBtn: '下载FACEGOOD',
    learnLink: 'https://avatary.yuque.com/org-wiki-avatary-uhm530/lrgwqg?#',
    downloadLink: 'https://www.avatary.com/download',
  },
  {
    logo: LogoZesen,
    logoMobile: LogoZesenMobile,
    class: 'zesen',
    text: '国产首个全流程的程序化三维内容生成软件',
    learnBtn: '了解zeno',
    downloadBtn: '下载zeno',
    learnLink: 'https://zenustech.com/learn',
    downloadLink: 'https://zenustech.com/download',
  },
  {
    logo: LogoPbrmax,
    logoMobile: LogoPbrmaxMobile,
    class: 'pbrmax',
    text: '海量高精度3D资产库平台',
    learnBtn: '了解PBRMAX',
    downloadBtn: '注册PBRMAX',
    learnLink: 'https://www.pbrmax.cn/?activeIndex=0&query={"pageNum":1}',
    downloadLink: 'https://www.pbrmax.cn/signup',
  },
]

const designInfo = new Map([
  [
    '参赛须知',
    [
      '本次大赛不限渲染方式，不限渲染器；',
      '参赛请先填写报名表，并下载大赛资料，按照官方要求进行创作；',
      '成功报名后，参赛者会收到参赛邮件，邮件内将提供技术支持资产获取方式，参赛者可提前注册好平台账号，按需免费使用；',
      '发布作品：作品发布至B站等社媒，视频标题：“作品名| 第三届瑞云渲染动画创作大赛”，并在视频简介中进行作品描述，手动标签#瑞云3D渲染动画创作大赛#关键词，评论区@官号“Renderbus瑞云渲染”；',
      '提交参赛：需要按照参赛邮件内的要求提交作品，作者名务必要与报名时填写的姓名一致；',
      '诚信参赛，不弄虚作假，不恶意侵占比赛资源，若有违反，主办方将追回侵占资源费用且追究法律责任;',
    ],
  ],
  [
    '作品提交内容',
    [
      '本次参赛作品可使用任何3D软件进行创作，请以分辨率1920*1080尺寸，每秒24帧画面进行渲染，总长7秒整（总共24fps x 7s = 168帧）；',
      '画面中应只含有作品本身画面，注意不要出现不相干的水印；',
      '除剧情需要外，不要做黑场、白场、渐入；',
      '作品可包含音效，但不要使用背景音乐，我们后期会进行统一配乐；',
      '最终提交的作品必须是使用编码H.264输出的mp4格式的视频，大小300M以内;',
    ],
  ],
  [
    '常见问题',
    [
      {
        question: '问题1：参赛需要收费吗？ 报名必须用真名吗？',
        answer:
          '答：参赛为免费，报名即可参与；报名姓名不做真名要求，也可以用圈名(5个字以内），但是需要始终保持一致',
      },
      {
        question: '问题2：报名信息填写错误，是否可以更改？',
        answer:
          '答：报名邮箱是唯一的，信息错误无法在网站直接更改，无法通过重复报名覆盖修改信息，请仔细检查确认好信息无误再填写报名表，如出现信息错误请更换邮箱重新报名',
      },
      {
        question: '问题3：一个人可以提交多少个作品？可以组队吗？',
        answer:
          '答：每人只允许提交一个作品，本次比赛主要为个人艺术家全流程比赛，如团队参赛成员不得超过3人',
      },
      {
        question: '问题4：一个人可以获得多个奖项吗？',
        answer: '答：可以，除了获得冠亚季与优秀奖奖项外，仍有资格获得人气奖/特别奖',
      },
      {
        question: '问题5：必须要用“大赛资料包”提供的动作吗？',
        answer: '答：不做要求，资料包中我们动捕了几套动作送给大家，与赛事模板以及要求无关',
      },
      {
        question: '问题6：如何提交作品？',
        answer:
          '答：参赛请务必先报名，报名成功后将收到参赛邮件，按照邮件内的要求提交作品即可。作品命名格式为“作品名_作者名”，作者名务必要与报名时填写的姓名一致。2024/02/24之前都可以提交，仅一次上传覆盖机会，我们将以最新作品为准',
      },
      {
        question: '问题7：比赛期间，我必须将参赛作品发布B站、站酷、小红书、抖音等平台吗？',
        answer:
          '答：必须要发布的平台为B站，发布时请在作品内标明该作品为“作品名| 第三届瑞云渲染动画创作大赛”，打好相关标签方便检索，并@瑞云官方账号“Renderbus瑞云渲染”。其他平台也建议发布，因为有利于参与最佳人气奖的“网络热度”评选，如选手不发也不做强制',
      },
      {
        question: '问题8：投稿后，社媒平台是否可以删除作品？',
        answer:
          '答：不可以，报名后不支持删除作品。B站有稿件修改替换功能，如想优化可以再替换，其他平台都不可以替换视频，请确认好以后再上传。另外，请保存好工程文件，后期可能会进行作品复核',
      },
      {
        question: '问题9：参赛作品是否可以带个人水印 /logo ?',
        answer:
          '答：为保证评审的公平公正性，上传到官方通道的作品不可以含有个人信息的特征标记，但是上传在B站等社媒平台的作品可以片尾增加“大赛资料包”中赛题水印（不强制）',
      },
      {
        question: '问题10：其他大赛的投稿作品是否可以参加本次大赛?',
        answer:
          '答：可以，但需避免侵犯其他主办方的权益，另外，不可以直接用前两届瑞云渲染大赛作品直接参赛',
      },
      {
        question: '问题11：我拿网上的概念设计图做成3D的参赛算侵权吗？大赛允许吗?',
        answer:
          '答：大赛允许，但是需要参赛者标明创意来源且获得原作者授权，原创分会被酌情扣除，不建议这么做',
      },
      {
        question: '问题12：我渲染完发现不满意，可以重新领券渲染吗？',
        answer:
          '答：每位选手仅一次免费云渲染权益，请确认好终版工程，再进行渲染哦，过程中遇到平台相关操作问题，可直接咨询站内客服，24h在线',
      },
      {
        question: '问题13：最佳人气奖将如何评选？',
        answer:
          '答：大赛资料包中会对人气奖评选做出明确解释，入围作品公布及投票的具体安排，请关注“Renderbus瑞云渲染”微信公众号',
      },
      {
        question: '问题14：获奖后将如何通知？如何联系大赛组委会？',
        answer:
          '答：获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式，可联系邮箱：RRC@rayvision.com  或者添加微信：rayvision1，工作人员将尽快与您取得联系',
      },
      {
        question: '问题15：如何关注大赛的最新动态？',
        answer: '答：请关注微信公众号“Renderbus瑞云渲染”，我们将在公众号中及时公布大赛的最新动态',
      },
    ],
  ],
])
const aboutInfo = new Map([
  [
    '获奖通知',
    [
      '现金部分奖金均为税前金额，将由主办方代扣代缴个人所得税，获奖通知将在最终结果公示后7个工作日内通过邮件方式发送至获奖作者参赛报名时预留的邮箱地址，请确认提供真实有效的联系方式',
      '获奖作者在活动结束后请注意查收邮件，并根据邮件提示内容及时配合主办方完成作品收集及奖品发放等工作；',
      '若获奖作者在限定时间内对获奖通知未做回应，则视为放弃奖项，由其他参赛作者取代；',
      '主办方将在比赛结束后安排奖品发放事宜，同一获奖作者可获得多个奖项（人气奖、特别奖）；',
      '最终赛果将在主办网站、协办网站等平台进行公示，并通过各媒体渠道进行曝光宣传； ',
    ],
  ],
  [
    '相关权益',
    [
      '所有参赛者向大赛提交的参赛作品需拥有独立完整的著作权，或已经取得著作权人的合法授权；',
      '主办方及合作方拥有对全部参赛作品进行展示、报道、宣传及用于市场活动的权利，参赛者保留对其作品的著作权。主办方及合作方有权将用于市场活动的作品加入赛事水印；',
      '作品一经提交，将视为参赛者同意并遵守比赛相关规定，若作品在商业使用中其著作权存在争议，主办方不承担因作品侵犯他人（或单位）的权利而产生的法律责任，由提供作品的参赛者承担全部法律责任；',
      '对存在争议的作品，大设计奖组委会有权保留甚至撤销该作品的参赛资格。如获奖作品存在知识产权等争议，大设计奖组委会有权撤销获奖资格，召回奖状、奖杯、奖品等荣誉；',
      '本次大赛最终解释权归主办方瑞云科技所有;',
    ],
  ],
  ['赛事交流', []],
  [
    '大赛简介',
    [
      '由中国“自助式云渲染"先行者Renderbus瑞云渲染主办发起的瑞云3D渲染动画创作大赛，截至目前已圆满举办两届，是目前国内3D动画艺术垂直领域规模最大、奖金池最高的顶级专业赛事，截至目前吸引数以千计的3D动画艺术家们加入其中，达千万流量曝光。',
      '大赛首届举办于2021年12月，以“翻转世界”为题，第二届大赛以“圆形轮廓”为题，参赛者需要以瑞云提供的3D动画模板场景为基础，进行7秒的3D动画渲染创作。',
      '3D动画崛起，必有我辈！瑞云3D渲染动画创作大赛旨在发掘和培养优秀3D动画人才、为CG创作者们提供展示自我的机会！大赛云集3D动画爱好者们一起探索与交流前沿的CG技术，创造属于自己的精彩！',
    ],
  ],
])
const partnerList = [
  {
    name: '主办方',
    class: 'host',
    content: [LogoRayvision, LogoLianxiang],
  },
  {
    name: '协办方',
    class: 'co-organizer',
    content: [LogoRenderbusPartner, LogoQingjiaoPartner, Logo3Dcat],
  },
  {
    name: '赞助方',
    class: 'sponsor',
    content: [
      LogoZhenxiang,
      LogoLuokeke,
      LogoHuiWang,
      LogoStyle3dPartner,
      LogoFacegoodPartner,
      LogoZesenPartner,
      LogoPbrmaxPartner,
      LogoD5,
    ],
  },
  {
    name: '支持单位',
    class: 'support-unit',
    content: [
      '欧特克软件(中国)\n有限公司',
      '中国电影美术学会\nCG艺术专委会',
      '中央美术学院\n国际数字艺术研究中心',
      '中国传媒大学\n动画与数字艺术学院',
      '广州美术学院\n视觉艺术设计学院',
      '中国美术学院\n创新设计学院',
      '西安美术学院\n影视动画系',
      '上海工艺美术学会\n数字艺术设计专业委员会',
      '湖北美术学院\n影视动画学院',
      '北京电影学院\n影视技术系',
      'CG世界',
      'CG模型网',
      '哔哩哔哩寻光',
      '特效小哥studio',
      'wuhu动画人空间',
    ],
  },
  {
    name: '大赛官方推荐用机——联想拯救者',
    class: 'liangxiang',
    content: [ThanksLiangxiang],
  },
  {
    name: '特别鸣谢',
    class: 'thanks',
    content: ['传消兄弟'],
  },
]

const rankArr = [
  { rankName: '冠军', rankPng: ChampionWheat, rankNameColor: '#FDD195' },
  { rankName: '亚军', rankPng: RunnerUpWheat, rankNameColor: '#C3C5C7' },
  { rankName: '季军', rankPng: ThirdWheat, rankNameColor: '#B87D65' },
  { rankName: '优秀奖', rankPng: CommonWheat, rankNameColor: '#7CB9FD' },
  { rankName: '最佳人气奖', rankPng: CommonWheat, rankNameColor: '#7CB9FD' },
]

const rankList = [
  [
    {
      id: 1,
      name: '施瑞岳',
      workName: 'Unreal2Real',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/1cc9ef73692a70a7b07b312d683cabc409e64406.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/1cc9ef73692a70a7b07b312d683cabc409e64406.png',
    },
  ],
  [
    {
      id: 2,
      name: '万人书',
      workName: '赛博夜',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/6198473cff7ec661038c575175ec4978a3c0d59b.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/6198473cff7ec661038c575175ec4978a3c0d59b.jpg',
    },
    {
      id: 3,
      name: '默',
      workName: '红烧刹车盘',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/a9d52aa104e2035604395825b21c5cfaafd677e6.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/a9d52aa104e2035604395825b21c5cfaafd677e6.jpg',
    },
    {
      id: 4,
      name: '不队',
      workName: '崩塌',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/dc34a554b1de3377e0574fbcd06d2028e38cdd61.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/dc34a554b1de3377e0574fbcd06d2028e38cdd61.jpg',
    },
  ],
  [
    {
      id: 5,
      name: '考拉OK',
      workName: '代号：打窝',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/232cc184dd27b10abec50c7b1b387897f743486b.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/232cc184dd27b10abec50c7b1b387897f743486b.jpg',
    },
    {
      id: 6,
      name: '磨太太的W',
      workName: '野路子',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/76bea528c2f033e40ffcb70fdfe5d75a50e488bb.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/76bea528c2f033e40ffcb70fdfe5d75a50e488bb.jpg',
    },
    {
      id: 7,
      name: 'ABIN',
      workName: 'Clang',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/ca0a589d1b76670ea960690904f0db0241f48559.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/ca0a589d1b76670ea960690904f0db0241f48559.jpg',
    },
    {
      id: 8,
      name: '彼尔德',
      workName: '生活',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/57790c43da072c328c2a91ee60b4de528f06ce07.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/57790c43da072c328c2a91ee60b4de528f06ce07.jpg',
    },
    {
      id: 9,
      name: '蜗里奥',
      workName: '空海',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/1789d99ac1e8e3422b93cb662c0a40bb5eb1c11c.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/1789d99ac1e8e3422b93cb662c0a40bb5eb1c11c.png',
    },
    {
      id: 10,
      name: '林俊龙、陈栩怡',
      workName: '我们仍未知道那天门后所看见的是什么',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/08060adda14a626be25ce403e2ab27f5a6af7fda.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/08060adda14a626be25ce403e2ab27f5a6af7fda.png',
    },
  ],
  [
    {
      id: 11,
      name: '王飞',
      workName: '夜袭',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/20984e7a7a7c34b06be501a93d8dabaad0ede430.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/20984e7a7a7c34b06be501a93d8dabaad0ede430.png',
    },
    {
      id: 12,
      name: '李浩翔',
      workName: '狙击',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/80c73a1a7ffa2a01bdcb6d108e64c9a18622422f.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/80c73a1a7ffa2a01bdcb6d108e64c9a18622422f.png',
    },
    {
      id: 13,
      name: 'vvb',
      workName: '亡命之徒',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/aaa242d556c27959750d21f39a79499c786ed9ba.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/aaa242d556c27959750d21f39a79499c786ed9ba.png',
    },
    {
      id: 14,
      name: '李智一',
      workName: '拯救者先遣队_星际龙骑',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/9af263a0e52585cd21e62ca27d02cbc065f36314.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/9af263a0e52585cd21e62ca27d02cbc065f36314.png',
    },
    {
      id: 15,
      name: '占鑫豪',
      workName: '星火',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/c102580e88be4e8aec946fa3b2b823b5429f5f0b.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/c102580e88be4e8aec946fa3b2b823b5429f5f0b.png',
    },
    {
      id: 16,
      name: 'YOGA',
      workName: '黑社会：悟空',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/ca8e834e94d04a6f83ef433421070ff95e2df453.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/ca8e834e94d04a6f83ef433421070ff95e2df453.png',
    },
    {
      id: 17,
      name: '彭楚璇',
      workName: '飞逐夏日',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/904f0b9e9a93c0ce90771185c0c6c934f126df6e.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/904f0b9e9a93c0ce90771185c0c6c934f126df6e.jpg',
    },
    {
      id: 18,
      name: '程远鹏',
      workName: '逃脱',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/28f78bbd341455abe88fa7a091b891f431ab860c.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/28f78bbd341455abe88fa7a091b891f431ab860c.png',
    },
    {
      id: 19,
      name: '赵牧石',
      workName: '一触即发',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/7ede0686204062821b2c2752daa0d166366b507e.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/7ede0686204062821b2c2752daa0d166366b507e.jpg',
    },
    {
      id: 20,
      name: '张平',
      workName: '亲子大电影之狂飙',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/20f115ba6a0543353a7156d4ece7e3235875678f.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/20f115ba6a0543353a7156d4ece7e3235875678f.png',
    },
    {
      id: 21,
      name: '陈伟超',
      workName: 'Fly',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/823de138618dc0d0a3f30ee158ec0c66d6f59995.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/823de138618dc0d0a3f30ee158ec0c66d6f59995.png',
    },
    {
      id: 22,
      name: '罗振鑫',
      workName: '靛蓝海与栖梦人',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/ff10ea613e7aef39a46e324ff419ca6218a982d6.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/ff10ea613e7aef39a46e324ff419ca6218a982d6.png',
    },
    {
      id: 23,
      name: 'ni ne',
      workName: '自由叩击',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/7d2a91f23865ab91b9ace13ac45a6ce7138630d4.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/7d2a91f23865ab91b9ace13ac45a6ce7138630d4.png',
    },
    {
      id: 24,
      name: '李枳楠',
      workName: '老太单挑lv99鳄霸！快到飞起，爽到上天！',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/22b9f3d1d314d4a80769c3f11290a5834c48de1e.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/22b9f3d1d314d4a80769c3f11290a5834c48de1e.png',
    },
    {
      id: 25,
      name: '黄永富',
      workName: '天马计划',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/f3b1a2042a31fad6fdf306df9e05c8b62df429bc.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/f3b1a2042a31fad6fdf306df9e05c8b62df429bc.png',
    },
    {
      id: 26,
      name: '释开达',
      workName: 'YARA',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/d44688bbcd6193ecf781944375345d101c6f583a.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/d44688bbcd6193ecf781944375345d101c6f583a.jpg',
    },
    {
      id: 27,
      name: '简启忠',
      workName: '车厢',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/682ed3f48371e70d9921e59bc12cc97dc5797b33.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/682ed3f48371e70d9921e59bc12cc97dc5797b33.png',
    },
    {
      id: 28,
      name: '昝富文',
      workName: '红与白',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/097c5bddcf4b58dadf5549bcaad575e309a074cb.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/097c5bddcf4b58dadf5549bcaad575e309a074cb.jpg',
    },
    {
      id: 29,
      name: '李昕宇',
      workName: '幽灵捕手',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/65602edc47cd74dea9a3ff863608e7ffd0e9d9c5.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/65602edc47cd74dea9a3ff863608e7ffd0e9d9c5.png',
    },
    {
      id: 30,
      name: '徐忠磊',
      workName: '冬去春来',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/66670af20b8f022ae6b88c3a03bb7b7c39bd48f3.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/66670af20b8f022ae6b88c3a03bb7b7c39bd48f3.jpg',
    },
  ],
  [
    {
      id: 31,
      name: '万人书',
      workName: '赛博夜',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/6198473cff7ec661038c575175ec4978a3c0d59b.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/6198473cff7ec661038c575175ec4978a3c0d59b.jpg',
      description: '最佳人气奖-网络平台热度奖（B站）',
    },
    {
      id: 32,
      name: '张平',
      workName: '亲子大电影之狂飙',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/20f115ba6a0543353a7156d4ece7e3235875678f.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/20f115ba6a0543353a7156d4ece7e3235875678f.png',
      description: '最佳人气奖-网络平台热度奖（站酷）',
    },
    {
      id: 33,
      name: '邬正雄',
      workName: '探索未知',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/687f84b46a62551d610317c1c071998311ac7345.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/687f84b46a62551d610317c1c071998311ac7345.png',
      description: '最佳人气奖-网络平台热度奖（抖音）',
    },
    {
      id: 34,
      name: '释开达',
      workName: 'YARA',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/d44688bbcd6193ecf781944375345d101c6f583a.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/d44688bbcd6193ecf781944375345d101c6f583a.jpg',
      description: '最佳人气奖-网络平台热度奖（小红书）',
    },
    {
      id: 35,
      name: '默',
      workName: '红烧刹车盘',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/a9d52aa104e2035604395825b21c5cfaafd677e6.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/a9d52aa104e2035604395825b21c5cfaafd677e6.jpg',
      description: '最佳人气奖-行业观众喜爱奖',
    },
    {
      id: 36,
      name: '刘祝',
      workName: '拾光者',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/78127eaf2860d829c90cc0c578fb492339ac5c38.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/78127eaf2860d829c90cc0c578fb492339ac5c38.png',
      description: '最佳人气奖-行业观众喜爱奖',
    },
    {
      id: 37,
      name: '舟午',
      workName: '战争爆发',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/24dc9dfa4917bb2b413c598a6d9a16ab23273e2c.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/24dc9dfa4917bb2b413c598a6d9a16ab23273e2c.png',
      description: '最佳人气奖-行业观众喜爱奖',
    },
    {
      id: 38,
      name: '吴滨宏',
      workName: '拯救者001',
      workLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/5747a4dc50a84ca8fd7e9240676d65411019da92.mp4',
      coverLink:
        'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/5747a4dc50a84ca8fd7e9240676d65411019da92.png',
      description: '最佳人气奖-票圈最受欢迎奖',
    },
  ],
]

const specialAwardWorkMap = new Map([
  [
    'Style3D特别奖',
    {
      headerJustifyContent: 'space-between',
      translateX: 0,
      pointX: 24,
      pointY: 45,
      pointActiveX: 6,
      pointActiveY: 52,
      afterActivePointX: 24,
      afterActivePointY: 45,
      awardContent: [
        {
          id: 39,
          name: '王飞',
          workName: '夜袭',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/20984e7a7a7c34b06be501a93d8dabaad0ede430.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/20984e7a7a7c34b06be501a93d8dabaad0ede430.png',
          awardName: '建模大师奖',
        },
        {
          id: 40,
          name: '张平',
          workName: '亲子大电影之狂飙',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/20f115ba6a0543353a7156d4ece7e3235875678f.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/20f115ba6a0543353a7156d4ece7e3235875678f.png',
          awardName: '解算精英奖',
        },
        {
          id: 41,
          name: 'ni ne',
          workName: '自由叩击',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/7d2a91f23865ab91b9ace13ac45a6ce7138630d4.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/7d2a91f23865ab91b9ace13ac45a6ce7138630d4.png',
          awardName: '视效魔法奖',
        },
        {
          id: 42,
          name: 'CZXA1',
          workName: '拯救者计划',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/a399c9b228ab7a0546b9cd054c01ab31a7385adb.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/a399c9b228ab7a0546b9cd054c01ab31a7385adb.png',
          awardName: '群众追捧奖',
        },
      ],
    },
  ],
  [
    'FACEGOOD特别奖',
    {
      headerJustifyContent: 'unset',
      translateX: -37,
      pointX: -1,
      pointY: 197,
      pointActiveX: -13,
      pointActiveY: 167,
      afterActivePointX: 2,
      afterActivePointY: 157,
      awardContent: [
        {
          id: 43,
          name: '青椒鱼',
          workName: '逗我？',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/2c1cd047d1b52b726bbc339cab1e59fab6f9462b.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/2c1cd047d1b52b726bbc339cab1e59fab6f9462b.jpg',
          awardName: '特别表情动画大师奖',
        },
        {
          id: 44,
          name: '马宁',
          workName: '锁定胜局',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/c9171dc01c6063554b4fa7609e19719f8589b26f.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/c9171dc01c6063554b4fa7609e19719f8589b26f.jpg',
          awardName: '面部表情动画创意奖',
        },
      ],
    },
  ],
  [
    'ZENO特别奖',
    {
      headerJustifyContent: 'unset',
      translateX: -24,
      pointX: 1,
      pointY: 313,
      pointActiveX: -14,
      pointActiveY: 280,
      afterActivePointX: -2,
      afterActivePointY: 270,
      awardContent: [
        {
          id: 45,
          name: '王培勇',
          workName: '异星危机',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/d3f883da4f65958c7acc8cecaa5408d89312abc4.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/d3f883da4f65958c7acc8cecaa5408d89312abc4.png',
          awardName: 'ZENO特别奖',
        },
      ],
    },
  ],
  [
    'PBRMAX特别奖',
    {
      headerJustifyContent: 'unset',
      translateX: 0,
      pointX: 24,
      pointY: 427,
      pointActiveX: 6,
      pointActiveY: 395,
      afterActivePointX: 6,
      afterActivePointY: 395,
      awardContent: [
        {
          id: 46,
          name: '彼尔德',
          workName: '生活',
          workLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/works/57790c43da072c328c2a91ee60b4de528f06ce07.mp4',
          coverLink:
            'http://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/coverImg/57790c43da072c328c2a91ee60b4de528f06ce07.jpg',
          awardName: 'PBRMAX特别奖',
        },
      ],
    },
  ],
])

function RenderContest() {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showPCMenu, setShowPCMenu] = useState(false)
  const [currentIndex, setCurrentIndex] = useState('#rc-top')
  const [awardType, setAwardType] = useState('Style3D特别奖4人')
  // eslint-disable-next-line no-unused-vars
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [designTag, setDesignTag] = useState('参赛须知')
  const [aboutTag, setAboutTag] = useState('获奖通知')
  const pageContainer = useRef(null)
  const video = useRef(null)
  const awardItemActive = specialAwardMap.get(awardType)
  const designItemActive = designInfo.get(designTag)
  const aboutItemActive = aboutInfo.get(aboutTag)
  const [judgeName, setJudgeName] = useState('边青山')
  const [awardIndex, setAwardIndex] = useState(0)
  const [specialImgIndex, setSpecialImgIndex] = useState(0)
  const [showPrevious, setShowPrevious] = useState(false)
  const judgeInfo = mainJudgesList1.find(item => item.name === judgeName)
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews)
  const isMobile = useMedia({ maxWidth: 600 })
  const [showAndongDetail, setShowAndongDetail] = useState(false)
  const [rankIndex, setRankIndex] = useState(0)
  const [rankWorkIndex, setRankWorkIndex] = useState(0)
  const [awardsWorkName, setAwardsWorkName] = useState('Style3D特别奖')
  const [awardsWorkIndex, setAwardsWorkIndex] = useState(0)
  const [awardsGroupWorkIndex, setAwardsGroupWorkIndex] = useState(0)
  const [showMBHistoryMatch, setShowMBHistoryMatch] = useState(false)
  const historyMatchRef = useRef(null)
  const menuRef = useRef(null)

  useClickOutside(historyMatchRef, () => setShowMBHistoryMatch(false), ['click'])
  useClickOutside(menuRef, () => setShowMobileMenu(false), ['click'])

  function handleScroll() {
    const achorNode = [...pageContainer.current.children].filter(node => node.id.includes('rc-'))
    for (let node of achorNode) {
      const offset = node.getBoundingClientRect()
      if (node.id.includes('rc-top')) {
        if (Math.abs(offset.top) > 74) {
          setShowPCMenu(true)
        } else {
          setShowPCMenu(false)
          setCurrentIndex('#rc-top')
        }
      } else {
        if (offset.top <= window.innerHeight && offset.bottom >= 0) {
          setCurrentIndex(`#${node.id}`)
          break
        }
      }
    }
  }

  useScroll(handleScroll)
  useEffect(() => {
    function handleVideoPlay(e) {
      setVideoPlaying(!e.target.paused)
    }
    const videoElement = video.current
    if (videoElement) {
      videoElement.addEventListener('pause', handleVideoPlay)
      videoElement.addEventListener('play', handleVideoPlay)
    }
    const anchorElement = document.getElementById(window.location.hash.split('#')[1])
    anchorElement && anchorElement.scrollIntoView(true)
    if (window.location.hash === '#rc-design') {
      setDesignTag('常见问题')
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('pause', handleVideoPlay)
        videoElement.addEventListener('play', handleVideoPlay)
      }
    }
  }, [])

  // useEffect(() => {
  //   const videoBanner = document.getElementById('banner-video')
  //   const imgBanner = document.getElementById('banner-bg')
  //   const handleLoadedMetadata = () => {
  //     if (!isMobile) {
  //       imgBanner.style.display = 'none'
  //       videoBanner.style.display = 'block'
  //       videoBanner.play()
  //       console.log(isMobile)
  //     }
  //   }
  //   videoBanner.addEventListener('loadedmetadata', handleLoadedMetadata)
  //   return () => {
  //     videoBanner.removeEventListener('loadedmetadata', handleLoadedMetadata)
  //   }
  // }, [isMobile])

  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title='第三届瑞云3D渲染动画创作大赛｜灵感汇聚，创意接力 - 瑞云科技'
        keywords='3d渲染动画大赛, 3D渲染大赛, cg动画大赛, 动画创作大赛'
        description='国内3D动画艺术垂直领域规模最大、奖金池最高的顶级赛事-第三届瑞云3D渲染动画创作大赛现已开启报名，赢现金奖品，就等你来！'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.png'
        sharePostSlug='rc2024.html'
      />
      <RCHeader>
        {headerItemList.map(item =>
          item.label === '往届回顾' ? (
            <>
              <HeaderItem
                key={item.label}
                className='previous-item'
                target={item.target}
                onClick={() => {
                  setShowPrevious(!showPrevious)
                }}
                onMouseEnter={() => {
                  setShowPrevious(true)
                }}
                onMouseLeave={() => {
                  setShowPrevious(false)
                }}
              >
                {item.label}
                <img
                  src={showPrevious ? UpIcon : PullIcon}
                  alt=''
                  onClick={() => {
                    setShowPrevious(!showPrevious)
                  }}
                />
                <div className='interval'></div>
                {showPrevious && (
                  <PreviousContain>
                    <a href='https://www.rayvision.com/rrc.html' rel='nofollow'>
                      第一届渲染大赛
                    </a>
                    <a href='https://www.renderbus.com/rc.html' rel='nofollow'>
                      第二届渲染大赛
                    </a>
                  </PreviousContain>
                )}
              </HeaderItem>
            </>
          ) : (
            <HeaderItem
              key={item.label}
              href={item.link}
              rel={item.rel}
              target={item.target}
              onClick={() => {
                if (item.link === '#rc-design') {
                  setDesignTag('常见问题')
                }
                setCurrentIndex(item.link)
              }}
            >
              {item.label}
            </HeaderItem>
          ),
        )}
        <RCTitle className='hideInPC'>
          <p>第三届瑞云3D渲染动画创作大赛</p>
          <p>2023.11.24 - 2024.02.24</p>
        </RCTitle>
        <SignButton href='/rc2024' target='_blank' rel='nofollow'>
          参赛作品展
        </SignButton>
        <HistoryMatch
          ref={historyMatchRef}
          onClick={() => setShowMBHistoryMatch(!showMBHistoryMatch)}
          active={showMBHistoryMatch}
        >
          <span>往届回顾</span>
          <span className='arrow'></span>
          <HistoryMatchDropDown>
            <HistoryMatchItem href='https://www.rayvision.com/rrc.html' rel='nofollow'>
              第一届渲染大赛
            </HistoryMatchItem>
            <HistoryMatchItem href='https://www.renderbus.com/rc.html' rel='nofollow'>
              第二届渲染大赛
            </HistoryMatchItem>
          </HistoryMatchDropDown>
        </HistoryMatch>
        <NavigateMenuIcon
          ref={menuRef}
          isActive={!showMobileMenu}
          onClick={() => {
            setShowMobileMenu(!showMobileMenu)
          }}
        />
        <NavigateMenu isActive={showMobileMenu} showPCMenu={showPCMenu}>
          <div className='menu-container'>
            <SignUpButton
              href='https://www.renderbus.com/rc2024'
              target='_blank'
              rel='nofollow'
              className='hideInMobile'
            >
              参赛作品展
            </SignUpButton>
            <SignButton href='/rc2024' target='_blank' rel='nofollow' className='side'>
              参赛作品展
            </SignButton>
            {mobileMenuItemList.map(item => (
              <NavigateMenuItem
                href={item.link}
                key={item.label}
                onClick={() => {
                  setCurrentIndex(item.link)
                  setShowMobileMenu(false)
                }}
                isActive={currentIndex === item.link}
              >
                {item.label}
              </NavigateMenuItem>
            ))}
            <BackToTop
              href='#rc-top'
              onClick={() => {
                setCurrentIndex('#rc-top')
                setShowMobileMenu(false)
              }}
            >
              回到顶部
            </BackToTop>
          </div>
          <QrContainer className='hideInMobile'>
            <img src={QrCode} alt='' />
            <p>官方交流群</p>
          </QrContainer>
        </NavigateMenu>
      </RCHeader>
      <PageContainer ref={pageContainer}>
        <IndexSection id='rc-top'>
          {/* <RC2024Banner isMain={false} style={{ maxWidth: '100vw' }} /> */}
          <VideoBanner style={{ maxWidth: '100vw' }} className='hideInMobile'>
            <video
              autoPlay={true}
              muted
              loop
              playsInline={false}
              id='banner-video'
              poster={BannerBg}
            >
              <source
                preload='true'
                type='video/mp4'
                src='https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.mp4'
                rel='nofollow'
              />
            </video>
          </VideoBanner>
          <VideoBanner style={{ maxWidth: '100vw' }} className='hideInPC'>
            <img src={BannerBgMb} alt='' id='banner-bg-mb' />
          </VideoBanner>
          <RCOrganization>
            <div className='banner-content'>
              <h1 className='title'>第三届瑞云3D渲染动画创作大赛</h1>
              <img src={SloganIcon} alt='' className='hideInMobile' />
              <img src={SloganMobileIcon} alt='' className='hideInPC' />
            </div>
          </RCOrganization>
        </IndexSection>
        <ImgWrapper>
          <img src={PageBg} alt='' className='hideInMobile' />
          <img src={PageMobileBg} alt='' className='hideInPC' />
        </ImgWrapper>
        <ResultsAnnouncedWrap id='rc-announced'>
          <RankAnnouncedWrap>
            <RankWorkInfoWrap hasHeader={rankList[rankIndex].length > 1}>
              {rankList[rankIndex].length > 1 && (
                <RankWorkInfoHeader>
                  <RankWorkButton
                    disabled={rankWorkIndex <= 0}
                    onClick={() =>
                      setRankWorkIndex(rankWorkIndex > 0 ? rankWorkIndex - 1 : rankWorkIndex)
                    }
                  >
                    上一个
                  </RankWorkButton>
                  {rankWorkIndex + 1}/{rankList[rankIndex].length}
                  <RankWorkButton
                    disabled={rankWorkIndex >= rankList[rankIndex].length - 1}
                    onClick={() =>
                      setRankWorkIndex(
                        rankWorkIndex < rankList[rankIndex].length - 1
                          ? rankWorkIndex + 1
                          : rankWorkIndex,
                      )
                    }
                  >
                    下一个
                  </RankWorkButton>
                </RankWorkInfoHeader>
              )}
              <RankContent>
                <RankVideo>
                  <video
                    key={rankList[rankIndex][rankWorkIndex].id}
                    playsInline
                    controls={true}
                    poster={rankList[rankIndex][rankWorkIndex].coverLink}
                  >
                    <source src={rankList[rankIndex][rankWorkIndex].workLink} />
                  </video>
                </RankVideo>
                <RankInfoWrap>
                  <RankInfoLeftWrap>
                    <div>作品：{rankList[rankIndex][rankWorkIndex].workName}</div>
                    <div>作者：{rankList[rankIndex][rankWorkIndex].name}</div>
                  </RankInfoLeftWrap>
                  <RankInfoDesc>
                    {rankList[rankIndex][rankWorkIndex]?.description || ''}
                  </RankInfoDesc>
                </RankInfoWrap>
              </RankContent>
            </RankWorkInfoWrap>
            <RankInfoSidebar hasHeader={rankList[rankIndex].length > 1}>
              {rankList.map((item, index) => (
                <RankInfoSidebarItem
                  key={item.id}
                  background={item[0].coverLink}
                  active={rankIndex === index}
                  onClick={() => {
                    setRankWorkIndex(0)
                    setRankIndex(index)
                  }}
                >
                  <RankInfoSidebarItemName
                    background={rankArr[index].rankPng}
                    color={rankArr[index].rankNameColor}
                    active={rankIndex === index}
                  >
                    {rankArr[index].rankName}
                  </RankInfoSidebarItemName>
                </RankInfoSidebarItem>
              ))}
            </RankInfoSidebar>
          </RankAnnouncedWrap>
          <AwardsWrap>
            <AwardsSidebar>
              <AwardsSidebarItemsWrap>
                {[...specialAwardWorkMap.keys()].map((item, index) => (
                  <AwardsSidebarItem
                    key={item}
                    translateX={specialAwardWorkMap.get(item).translateX}
                    active={item === awardsWorkName}
                    background={specialAwardWorkMap.get(item).awardContent[0].coverLink}
                    onClick={() => {
                      setAwardsWorkName(item)
                      setAwardsGroupWorkIndex(0)
                      setAwardsWorkIndex(index)
                    }}
                  >
                    <AwardsSidebarItemName
                      background={CommonWheat}
                      active={item === awardsWorkName}
                    >
                      {item}
                    </AwardsSidebarItemName>
                  </AwardsSidebarItem>
                ))}
              </AwardsSidebarItemsWrap>
              <AwardsScrollBar background={AwardsBg}>
                {[...specialAwardWorkMap.keys()].map((item, index) => (
                  <AwardsScrollBarItem
                    key={index}
                    left={
                      awardsWorkIndex > index
                        ? specialAwardWorkMap.get(item).afterActivePointX
                        : specialAwardWorkMap.get(item).pointX
                    }
                    top={
                      awardsWorkIndex > index
                        ? specialAwardWorkMap.get(item).afterActivePointY
                        : specialAwardWorkMap.get(item).pointY
                    }
                    leftActive={specialAwardWorkMap.get(item).pointActiveX}
                    topActive={specialAwardWorkMap.get(item).pointActiveY}
                    active={item === awardsWorkName}
                  />
                ))}
              </AwardsScrollBar>
            </AwardsSidebar>
            <AwardsInfoWrap>
              <AwardsInfoHeader
                justifyContent={specialAwardWorkMap.get(awardsWorkName).headerJustifyContent}
              >
                {specialAwardWorkMap.get(awardsWorkName).awardContent.map((item, index) => (
                  <AwardsInfoHeaderItem
                    key={item.awardName}
                    onClick={() => setAwardsGroupWorkIndex(index)}
                    active={index === awardsGroupWorkIndex}
                  >
                    {item.awardName}
                  </AwardsInfoHeaderItem>
                ))}
              </AwardsInfoHeader>
              <RankContent>
                <RankVideo>
                  <video
                    key={
                      specialAwardWorkMap.get(awardsWorkName).awardContent[awardsGroupWorkIndex].id
                    }
                    playsInline
                    controls={true}
                    poster={
                      specialAwardWorkMap.get(awardsWorkName).awardContent[awardsGroupWorkIndex]
                        .coverLink
                    }
                  >
                    <source
                      src={
                        specialAwardWorkMap.get(awardsWorkName).awardContent[awardsGroupWorkIndex]
                          .workLink
                      }
                    />
                  </video>
                </RankVideo>
                <RankInfoWrap>
                  <RankInfoLeftWrap>
                    <div>
                      作品：
                      {
                        specialAwardWorkMap.get(awardsWorkName).awardContent[awardsGroupWorkIndex]
                          .workName
                      }
                    </div>
                    <div>
                      作者：
                      {
                        specialAwardWorkMap.get(awardsWorkName).awardContent[awardsGroupWorkIndex]
                          .name
                      }
                    </div>
                  </RankInfoLeftWrap>
                  <RankInfoDesc></RankInfoDesc>
                </RankInfoWrap>
              </RankContent>
            </AwardsInfoWrap>
          </AwardsWrap>
        </ResultsAnnouncedWrap>
        <RCWordsSection id='rc-words'>
          <h2>大赛引言</h2>
          <div className='words-container'>
            {words.map(arr => (
              <div className='words-group' key={arr[0]}>
                {arr.map(p => (
                  <p className='word' key={p}>
                    {p}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </RCWordsSection>
        <RCDateSection id='rc-time'>
          <img src={Title01} alt='' />
          <h2>
            大<Slash></Slash>赛<Slash></Slash>时<Slash></Slash>间
          </h2>
          <div className='dates-container'>
            <img src={RenderContestTime} alt='' className='hideInMobile' />
            <img src={RenderContestTimeMobile} alt='' className='hideInPC' />
          </div>
        </RCDateSection>
        <RCGroupSection id='rc-group'>
          <img src={Title02} alt='' />
          <h2>
            面<Slash></Slash>向<Slash></Slash>群<Slash></Slash>体
          </h2>
          <div className='group-container hideInMobile'>
            <img src={Group} alt='' />
            <img src={Curve} alt='' className='curve' />
          </div>
          <img src={GroupsMobile} alt='' className='hideInPC' />
        </RCGroupSection>
        <RCAwardSection id='rc-award'>
          <img src={Title03} alt='' />
          <h2>
            大<Slash></Slash>赛<Slash></Slash>奖<Slash></Slash>品
          </h2>
          <AwardTotal>
            <p>奖品总价值</p>
            <p className='total-num'>1,102,584</p>
          </AwardTotal>
          <div className='award-container hideInMobile'>
            {awardGroupMap2.map(item => (
              <AwardItem
                className={item.name}
                titleColor={item.titleColor}
                otherAmountColor={item.otherAmountColor}
                lineColor={item.lineColor}
                key={item.name}
              >
                <img src={item.bgImg} alt='' />
                <p className='title'>{item.title}</p>
                <p className='num'>{item.num}</p>
                <div className='line'></div>
                <p className='total'>
                  <span>￥</span>
                  {item.total}
                </p>
                <p className='amount'>{item.amount}</p>
                {item.other.map((i, index) => (
                  <div key={index}>
                    <p className='amount'>
                      {i.amount && (
                        <>
                          <span>价值￥</span>
                          {i.amount}
                        </>
                      )}
                    </p>
                    {i.content.map(awardItem => (
                      <p className='award-item' key={awardItem.award}>
                        {awardItem.award}
                        <span className={'award-item-' + index}>{awardItem.tip}</span>
                      </p>
                    ))}
                  </div>
                ))}
              </AwardItem>
            ))}
          </div>
          <div className='award-container-mobile hideInPC'>
            <img
              src={IconLeft}
              alt=''
              className='left-icon'
              hidden={awardIndex <= 0}
              onClick={() => setAwardIndex(awardIndex - 1)}
            />
            <AutoPlaySwipeableViews
              autoplay
              interval={10000}
              enableMouseEvents
              index={awardIndex}
              onChangeIndex={i => {
                setAwardIndex(i)
              }}
            >
              {awardGroupMap2.map((item, index) => (
                <AwardItem
                  className={item.name}
                  titleColor={item.titleColor}
                  otherAmountColor={item.otherAmountColor}
                  lineColor={item.lineColor}
                >
                  <img src={item.bgImg} alt='' />
                  <p className='title'>{item.title}</p>
                  <p className='num'>{item.num}</p>
                  <div className='line'></div>
                  <p className='total'>
                    <span>￥</span>
                    {item.total}
                  </p>
                  <p className='amount'>{item.amount}</p>
                  {item.other.map(i => (
                    <>
                      {i.amount && (
                        <p className='amount'>
                          <span>价值￥</span>
                          {i.amount}
                        </p>
                      )}
                      {i.content.map(awardItem => (
                        <p className='award-item'>
                          {awardItem.award}
                          <span>{awardItem.tip}</span>
                        </p>
                      ))}
                    </>
                  ))}
                  <div className='dot-list'>
                    {awardGroupMap2.map((i, ind) => (
                      <div
                        className={`dot ${ind === index ? 'active' : ''}`}
                        onClick={() => setAwardIndex(ind)}
                      ></div>
                    ))}
                  </div>
                </AwardItem>
              ))}
            </AutoPlaySwipeableViews>
            <img
              src={IconRight}
              alt=''
              className='right-icon'
              hidden={awardIndex >= awardGroupMap2.length - 1}
              onClick={() => setAwardIndex(awardIndex + 1)}
            />
          </div>
          <h2>
            特<Slash></Slash>别<Slash></Slash>奖
          </h2>
          {/* <p className='total-value'>
            总价值达<span>10,0000</span>
          </p> */}
          <AwardTotal>
            <p>奖品总价值</p>
            <p className='total-num'>264,265</p>
          </AwardTotal>
          <div className='special-award'>
            <div className='award-type'>
              {[...specialAwardMap.keys()].map(type => (
                <AwardType
                  key={type}
                  isActive={awardType === type}
                  onClick={() => {
                    setSpecialImgIndex(0)
                    setAwardType(type)
                  }}
                  onMouseEnter={() => {
                    setSpecialImgIndex(0)
                    setAwardType(type)
                  }}
                >
                  {type}
                </AwardType>
              ))}
            </div>
            <div
              className={`award ${awardItemActive.class ? awardItemActive.class : ''}`}
              key={awardItemActive.calss}
            >
              <div className='img-list hideInMobile'>
                {awardItemActive.awardsImgList.map(item => (
                  <img src={item} alt='' />
                ))}
              </div>
              <div className='text-content hideInMobile'>
                {awardItemActive.awardContent.map(item => (
                  <div className='text-item'>
                    {['zeno', 'wanghua'].includes(awardItemActive.class) ? (
                      <div className='award-title'>
                        <p className='title'>{item.title}</p>
                        <p className='title-content'>{item.titleContent}</p>
                      </div>
                    ) : (
                      <div className='award-title'>
                        <span className='title'>{item.title}</span>
                        <span className='title-content'>{item.titleContent}</span>
                      </div>
                    )}
                    <p className='total-amount'>{item.totalAmount}</p>
                    {item.content.map(i => (
                      <p className='award-content'>
                        {i.amount && <span className='amount'>{i.amount}</span>}
                        <span className='text'>{i.text}</span>
                      </p>
                    ))}
                  </div>
                ))}
              </div>
              <AwardContentMobile className='hideInPC'>
                <div className='img-list-mobile'>
                  <img
                    src={IconLeft}
                    alt=''
                    className='left-icon'
                    hidden={specialImgIndex <= 0}
                    onClick={() => setSpecialImgIndex(specialImgIndex - 1)}
                  />
                  <AutoPlaySwipeableViews
                    autoplay
                    interval={10000}
                    enableMouseEvents
                    index={specialImgIndex}
                    onChangeIndex={i => {
                      setSpecialImgIndex(i)
                    }}
                  >
                    {awardItemActive.awardsImgList.map((item, index) => (
                      <div className='img-and-dot'>
                        <img src={item} alt='' className='img-award' />
                        {awardItemActive.awardsImgList.length > 1 && (
                          <div className='dot-list'>
                            {awardItemActive.awardsImgList.map((i, ind) => (
                              <div
                                className={`dot ${ind === index ? 'active' : ''}`}
                                onClick={() => setSpecialImgIndex(ind)}
                              ></div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </AutoPlaySwipeableViews>
                  <img
                    src={IconRight}
                    alt=''
                    className='right-icon'
                    hidden={specialImgIndex >= awardItemActive.awardsImgList.length - 1}
                    onClick={() => setSpecialImgIndex(specialImgIndex + 1)}
                  />
                </div>
                <div className='text-content-mobile'>
                  {awardType === 'Style3D特别奖4人' ? (
                    awardItemActive.awardContent.map(item => (
                      <div className='text-item'>
                        <p className='title'>{item.title}</p>
                        <p className='title-content'>{item.titleContent}</p>
                        <p className='total-amount'>{item.totalAmount}</p>
                        {item.content.map(i => (
                          <p className='award-content'>
                            {i.amount && <span className='amount'>{i.amount}</span>}
                            <span className='text style3d-text'>{i.text}</span>
                          </p>
                        ))}
                      </div>
                    ))
                  ) : (
                    <AutoPlaySwipeableViews
                      autoplay
                      interval={10000}
                      enableMouseEvents
                      index={specialImgIndex}
                      onChangeIndex={i => {
                        setSpecialImgIndex(i)
                      }}
                    >
                      {awardItemActive.awardContent.map(item => (
                        <div className='text-item'>
                          <p className='title'>{item.title}</p>
                          <p className='title-content'>{item.titleContent}</p>
                          <p className='total-amount'>{item.totalAmount}</p>
                          {item.content.map(i => (
                            <p className='award-content'>
                              {i.amount && <span className='amount'>{i.amount}</span>}
                              <span className='text'>{i.text}</span>
                            </p>
                          ))}
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>
                  )}
                </div>
              </AwardContentMobile>
            </div>
          </div>
        </RCAwardSection>
        <RCJudgesSection id='rc-judges'>
          <img src={Title04} alt='' />
          <h2>
            大<Slash></Slash>赛<Slash></Slash>主<Slash></Slash>评<Slash></Slash>委
          </h2>
          <MainJudges>
            {JudgesList.map((item, index) => (
              <MainJudgeItem
                key={item.name}
                className={
                  item.name === '安东尼·拉默里纳拉' && showAndongDetail ? 'andong-detail' : ''
                }
                onMouseEnter={() => {
                  item.name === '安东尼·拉默里纳拉' && setShowAndongDetail(true)
                }}
                onMouseLeave={() => {
                  item.name === '安东尼·拉默里纳拉' && setShowAndongDetail(false)
                }}
              >
                {item.name === '安东尼·拉默里纳拉' && showAndongDetail ? (
                  <img src={item.imgHover} alt='' className='avatar' />
                ) : (
                  <img src={item.img} alt='' className='avatar' />
                )}
                <div>
                  <p className='name'>{item.name}</p>
                  <p className='english-name'>{item.englishName}</p>
                  {item.name === '安东尼·拉默里纳拉' ? (
                    <>
                      {!showAndongDetail && <p className='intro'>{item.intro}</p>}
                      {showAndongDetail && isMobile && (
                        <p className='intro'>{item.introHover[0]}</p>
                      )}
                      {showAndongDetail && !isMobile && (
                        <div className='intro'>
                          {item.introHover.map(i => (
                            <p style={{ marginBottom: '5px' }}>{i}</p>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <p className='intro'>{item.intro}</p>
                  )}
                </div>
                {item.name === '安东尼·拉默里纳拉' && !showAndongDetail && (
                  <img src={IconMore} alt='' className='icon-more' />
                )}
              </MainJudgeItem>
            ))}
          </MainJudges>
          <h2>
            大<Slash></Slash>赛<Slash></Slash>评<Slash></Slash>审<Slash></Slash>团
          </h2>
          <ProfileContainer>
            <div className='judge-info hideInMobile'>
              <div>
                <p className='name'>{judgeInfo.name}</p>
                <p className='english-name'>{judgeInfo.englishName}</p>
                <div className='intro-content'>
                  {judgeInfo.intro.map(item => (
                    <p className='intro'>{item}</p>
                  ))}
                </div>
              </div>
              <img src={judgeInfo.infoAvatar} alt='' />
            </div>
            <div className='judge-info-mobile hideInPC'>
              <div>
                <div>
                  <p className='name'>{judgeInfo.name}</p>
                  <p className='english-name'>{judgeInfo.englishName}</p>
                </div>
                <img src={judgeInfo.infoAvatar} alt='' />
              </div>
              <div className='intro-content'>
                {judgeInfo.intro.map(item => (
                  <p className='intro'>{item}</p>
                ))}
              </div>
            </div>
            <div className='avater-list'>
              {mainJudgesList1.map(item => (
                <div
                  className='judge-intro-item'
                  onClick={() => setJudgeName(item.name)}
                  onMouseEnter={() => {
                    !isMobile && setJudgeName(item.name)
                  }}
                >
                  <img
                    src={judgeName === item.name ? item.imgHover : item.img}
                    alt=''
                    key={item.name}
                    isActive={judgeInfo.name === item.name}
                  />
                  <div className='intro-text'>
                    <p className='name'>{item.name}</p>
                    <div className='position hideInMobile'>
                      {item.position.map(i => (
                        <p>{i}</p>
                      ))}
                    </div>
                    <div className='position hideInPC'>
                      {item.positionMb.map(i => (
                        <p>{i}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              {/* <img src={Planet} alt='' className='planet-img hideInPC' /> */}
            </div>
          </ProfileContainer>
        </RCJudgesSection>
        <RCContentSection id='rc-content'>
          <img src={Title05} alt='' />
          <h2>
            大<Slash></Slash>赛<Slash></Slash>内<Slash></Slash>容
          </h2>
          <div className='content'>
            <div
              className='video-container'
              onClick={() => {
                if (video.current.paused) {
                  video.current.play()
                } else {
                  video.current.pause()
                }
              }}
            >
              <video ref={video} preload='true' playsInline>
                <source src='https://rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/rule-video.mp4' />
              </video>
              <img src={PauseImg} alt='' className={videoPlaying ? 'hide' : ''} />
            </div>
            <div className='text-container'>
              <p>用7秒钟进行创意接力</p>
              <span>
                ·
                7s时间，你想用3D艺术讲述一个怎样的故事？瑞云携手联想拯救者一起来“拯活”啦！选手需要以大赛要求的规则进行延展创作，用7s的时间呈现角色在该次元发生的故事，让灵感汇聚，让创意接力！
              </span>
              <span>
                · 通过巧妙且合理的方式设计一个联想拯救者大Y元素的“载体/介质”，作为每个7s故事的起点。
                不管是贴图模型、浮雕花纹、缝隙光影等等都可任意发挥，有个大体形状和结构，具有一定的可识别性。“角色”先与“载体/介质”互动出现，后又消失在画面中，中间路径不做要求；
              </span>
              <span>
                ·
                作品画面风格不限，允许三渲二、写实、卡通、漫画、水墨……渲染呈现出来的任何画风，都可接受；
              </span>
              <span>
                ·
                题材遵守“四不原则”：不违法，不三俗，不侵权，不惹事（不违反中国法律；不庸俗、低俗、媚俗；不侵犯他人合法权益；不涉及意识形态等争议话题）
              </span>
            </div>
          </div>
          <a
            className='donwload'
            download='大赛资料包 - 第三届瑞云3D渲染动画创作大赛.rar'
            href='https://www.renderbus.com/rrc-03/%E5%A4%A7%E8%B5%9B%E8%B5%84%E6%96%99%E5%8C%85.rar'
            rel='nofollow'
          >
            大赛资料下载
            <img src={DownloadImg} alt='' />
          </a>
          <h2>
            评<Slash></Slash>选<Slash></Slash>标<Slash></Slash>准
          </h2>
          <div className='criter-content'>
            {criteriaList.map(item => (
              <div className='criter-item'>
                <p>{item.title}</p>
                <img src={CriteriaSlice} alt='' />
                <p>{item.content}</p>
              </div>
            ))}
          </div>
          <h2>
            技<Slash></Slash>术<Slash></Slash>支<Slash></Slash>持
          </h2>
          <div className='support-content'>
            {supportList.map(item => (
              <div className='support-item'>
                <img src={item.logo} alt='' className='hideInMobile' />
                <img src={item.logoMobile} alt='' className='hideInPC' />
                <p>{item.text}</p>
                <div className='btn-group'>
                  <a
                    href={item.learnLink}
                    className={item.class}
                    rel={item.learnRel || 'nofollow'}
                    target='_blank'
                  >
                    {item.learnBtn}
                  </a>
                  <a
                    href={item.downloadLink}
                    className={item.class}
                    rel={item.downloadRel || 'nofollow'}
                    target='_blank'
                  >
                    {item.downloadBtn}
                  </a>
                </div>
              </div>
            ))}
          </div>
          <p className='sources-intro'>
            参赛者可在参赛期间免费使用以上系列产品，
            <a href='https://www.kdocs.cn/l/cnoJZJCOCEgN' target='_blank' rel='nofollow'>
              点击此处
            </a>{' '}
            查看赛事专属软件资源使用介绍
          </p>
        </RCContentSection>
        <RCDesignSection id='rc-design'>
          <img src={Title06} alt='' />
          <h2>
            设<Slash></Slash>计<Slash></Slash>说<Slash></Slash>明
          </h2>
          <div className='design-container'>
            <div className='desin-tag'>
              {[...designInfo.keys()].map(tag => (
                <AwardType
                  key={tag}
                  isActive={designTag === tag}
                  onMouseEnter={() => setDesignTag(tag)}
                  onClick={() => setDesignTag(tag)}
                >
                  {tag}
                </AwardType>
              ))}
            </div>
            <div className='design-content'>
              {designTag === '常见问题' ? (
                <div className='qa-content'>
                  <div className='real-time'>
                    <span>实时更新文档：</span>
                    <img src={IconWord} alt='' />
                    <a href='https://www.kdocs.cn/l/cuGJeC4FpaA6?openfrom=docs' target='_blank'>
                      “创意接力”大赛常见问题解答（实时更新）
                    </a>
                  </div>
                  {designItemActive.map(item => (
                    <div className='qa-item'>
                      <p className='question'>{item.question}</p>
                      <p className='answer'>{item.answer}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {designItemActive.map((item, index) => (
                    <div className='other-item'>
                      <div>{index + 1}.</div>
                      <div>{item}</div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </RCDesignSection>
        <RCAboutSection id='rc-about'>
          <img src={Title07} alt='' />
          <h2>
            关<Slash></Slash>于<Slash></Slash>大<Slash></Slash>赛
          </h2>
          <div className='about-container'>
            <div className='about-tag'>
              {[...aboutInfo.keys()].map(tag => (
                <AwardType
                  key={tag}
                  isActive={aboutTag === tag}
                  onMouseEnter={() => setAboutTag(tag)}
                  onClick={() => setAboutTag(tag)}
                >
                  {tag}
                </AwardType>
              ))}
            </div>
            <div className='about-rc'>
              {aboutTag === '赛事交流' ? (
                <div className='talk-content'>
                  <img src={QrcodeTalk} alt='' />
                  <div>
                    <p>想跟技术大咖们学习CG技巧吗？</p>
                    <p>想和CG小伙伴们交流创作经验吗？</p>
                    <p>
                      扫码或微信添加小助手<span>rayvision1</span>，并备注<span>“渲染大赛”</span>
                    </p>
                    <p>加入我们的赛事交流群，一起玩转创意！</p>
                    <p>
                      官方通知邮箱<span>RRC@rayvision.com</span>
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {aboutItemActive.map((item, index) => (
                    <div
                      className={`other-content ${aboutTag === '大赛简介' ? 'about-intro' : ''}`}
                    >
                      {aboutTag !== '大赛简介' && <div>{index + 1}.</div>}
                      <div>{item}</div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          {/* <a href='https://www.renderbus.com/rc-form.html' rel='nofollow' target='_blank'>
            立即报名
          </a> */}
          <div className='partner-container'>
            <h2>
              合<Slash></Slash>作<Slash></Slash>伙<Slash></Slash>伴
            </h2>
            <div className='partner'>
              {partnerList.map(item => (
                <div>
                  <p className='partner-name'>{item.name}</p>
                  <div className={`partner-content ${item.class ? item.class : ''}`}>
                    {item.content.map(i => (
                      <div className='partner-item'>
                        {['support-unit', 'thanks'].includes(item.class) ? (
                          i.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index !== i.split('\n').length - 1 && <br />}
                            </React.Fragment>
                          ))
                        ) : (
                          <img src={i} alt='' />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </RCAboutSection>
        <PageFooter>
          <p>
            <img src={PoliceIcon} alt='' />© 2024
            <a href='https://www.rayvision.com/' rel='nofollow'>
              深圳市瑞云科技股份有限公司
            </a>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
              粤ICP备12028569号
            </a>
          </p>
          <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
        </PageFooter>
      </PageContainer>
      <RCStyle />
    </React.Fragment>
  )
}

export default RenderContest
